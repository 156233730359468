
// Modules
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { config } from '@fortawesome/fontawesome-svg-core'
import * as icons from '@fortawesome/free-brands-svg-icons'

// Styles
config.autoAddCss = false
const listStyles = {
  padding: 0,
  marginLeft: 0,
  listStyle: 'none',
  clear: 'both'
}

const itemStyle = {
  margin: '0 5px 5px 0',
  padding: '2px 5px',
  float: 'left',
  color: '#fff',
  background: '#1c1c1c'
}

// Export
export default ({ technologies = [] }) => {
  return (
    <ul style={listStyles}>
      {technologies.map((technology, i) => (
        <li key={`technology-list-${i}`} style={itemStyle}>
          {icons[technology.icon] ? <FontAwesomeIcon style={{ marginRight: '5px' }} icon={icons[technology.icon]} /> : null}
          {technology.name}
        </li>
      ))}
    </ul>
  )
}
