
// Modules
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { config } from '@fortawesome/fontawesome-svg-core'
import * as icons from '@fortawesome/free-brands-svg-icons'

// Styles
config.autoAddCss = false
const listStyles = { padding: 0, marginLeft: 0, listStyle: 'none' }

// Export
export default ({ socialLinks = [] }) => {
  return (
    <ul style={listStyles}>
      {socialLinks.map((socialLink, i) => (
        <li key={`social-link-list-${i}`}>
          <a href={socialLink.href}>
            <FontAwesomeIcon style={{ marginRight: '10px', width: '30px' }} icon={icons[socialLink.icon]} />
            {socialLink.name}
          </a>
        </li>
      ))}
    </ul>
  )
}
