// Modules
import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import SocialLinksList from "../components/social-links-list";
import TechnologiesList from "../components/technologies-list";
// import RecaptchaForm from '../components/recaptcha-form'
import BgImage from "../components/bg-image";

// Styles
config.autoAddCss = false;
const parentStyles = {};
const heroSection = {
  position: "relative",
  padding: "64px",
  minHeight: "370px",
  height: "100vh",
};
const sectionStyle = {
  position: "relative",
  padding: "64px 49px",
};

// Export
export default ({ data }) => {
  const title = data.site.siteMetadata.title;
  const description = data.site.siteMetadata.description;
  const socialLinks = data.socialLinks.entries.map((entry) => entry.socialLink);
  const technologies = data.technologies.entries.map(
    (entry) => entry.technology
  );
  console.log(data);
  const sizes = data.file.childImageSharp.sizes;

  return (
    <div style={parentStyles}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <BgImage sizes={sizes} />
      <section style={heroSection}>
        <div
          style={{
            position: "absolute",
            bottom: "64px",
            left: "64px",
            marginRight: "64px",
          }}
        >
          <div
            style={{
              padding: "2rem 2rem 3rem",
              background: "#fff",
              color: "#1c1c1c",
            }}
          >
            <h1 style={{ fontWeight: "700", marginBottom: "0.5rem" }}>
              Dominik Lessel
            </h1>
            <h2 style={{ fontWeight: "100", marginBottom: 0 }}>
              Independent Technology
              <br />
              Consultant
            </h2>
          </div>
          <div
            className="dark-grey-bg"
            style={{
              margin: "-30px 0 0 2rem",
              width: "60px",
              height: "60px",
              padding: "5px",
              color: "#ffc001",
            }}
          >
            <FontAwesomeIcon
              style={{ width: "50px", height: "50px" }}
              icon={faArrowDown}
            />
          </div>
        </div>
      </section>
      <section style={sectionStyle}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm mb-4">
              <h3>
                — I'm an Independent Technology Consultant based in Düsseldorf
              </h3>
              <p>
                Digital services and products have always fascinated me. As well
                as the overlap between design and development. That's why my
                skills are broadly diversified: conceptual design of business
                models, ux to design or front-end to back-end development.
              </p>
            </div>
            <div className="col-sm mb-4">
              <h4>Technologies and services:</h4>
              <TechnologiesList technologies={technologies} />
            </div>
            <div className="col-sm">
              <h4>Find me on:</h4>
              <SocialLinksList socialLinks={socialLinks} />
            </div>
          </div>
        </div>
      </section>
      <section className="dark-grey-bg" style={sectionStyle}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-4">
              <h3 className="mb-3">Contact</h3>
              <p className="mb-0">
                Email:{" "}
                <a href="mailto:dominik@lessel.xyz">dominik@lessel.xyz</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "img/background.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 1920) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    technologies: allTechnologiesJson {
      entries: edges {
        technology: node {
          icon
          name
        }
      }
    }
    socialLinks: allSocialLinksJson {
      entries: edges {
        socialLink: node {
          icon
          href
          name
        }
      }
    }
  }
`;
